import React from 'react';
import { TABS } from 'utils/tabsConstants';
import './filterTabs.scss';
import Button from '../Button';

const FilterTab = ({ active, changeTab, hasSalePrice }) => {
  return (
    <div className="filterTabs">
      <Button
        type={`app-secondary-button ${active === TABS.all ? 'active' : ''}`}
        handleClick={() => changeTab(TABS.all)}
      >
        ALL
      </Button>
      <Button
        type={`app-secondary-button ${active === TABS.sameEra ? 'active' : ''}`}
        handleClick={() => changeTab(TABS.sameEra)}
      >
        SAME ERA
      </Button>
      <Button
        type={`app-secondary-button ${
          active === TABS.sameBrand ? 'active' : ''
        }`}
        handleClick={() => changeTab(TABS.sameBrand)}
      >
        SAME BRAND
      </Button>
      {hasSalePrice && (
        <Button
          type={`app-secondary-button ${active === TABS.price ? 'active' : ''}`}
          handleClick={() => changeTab(TABS.price)}
        >
          SAME PRICE
        </Button>
      )}
    </div>
  );
};
export default FilterTab;
