import React, { useState, useEffect } from 'react';
import styles from './vehicleDetails.module.scss';
import { mapRelatedLots } from 'shared/helpers/vehicles/vehicleDetails';
import VehicleCard from 'components/VehicleCard';
import FilterTab from 'components/FilterTabs';
import { TABS } from 'utils/tabsConstants';
import { NoResult } from './noResultVehicles';

const RelatedVehicles = ({
  relatedVehiclesData,
  activeTab,
  changeActiveTab,
  hasSalePrice,
  relatedVehiclesLoading,
}) => {
  const [relatedVehicles, setRelatedVehicles] = useState([]);
  const [prevAllRelatedVehicles, setPrevAllRelatedVehicles] = useState([]);
  const [prevEraRelatedVehicles, setPrevEraRelatedVehicles] = useState([]);
  const [prevBrandRelatedVehicles, setPrevBrandRelatedVehicles] = useState([]);
  const [prevPriceRelatedVehicles, setPrevPriceRelatedVehicles] = useState([]);

  const getRelatedVehicles = () => {
    let relatedVehicles = relatedVehiclesData
      ? mapRelatedLots(relatedVehiclesData ?? [])
      : [];

    if (activeTab === TABS.all) {
      prevEraRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
      prevBrandRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
      prevPriceRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
    } else if (activeTab === TABS.sameEra) {
      prevAllRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
      prevBrandRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
      prevPriceRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
    } else if (activeTab === TABS.sameBrand) {
      prevAllRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
      prevEraRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
      prevPriceRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
    } else if (activeTab === TABS.price) {
      prevAllRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
      prevEraRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
      prevBrandRelatedVehicles.forEach(element => {
        relatedVehicles = relatedVehicles.filter(
          vehicle => vehicle?.lotNumber !== element?.lotNumber
        );
      });
    }

    if (activeTab === TABS.all) {
      setPrevAllRelatedVehicles(
        relatedVehicles.length > 3
          ? relatedVehicles.slice(0, 3)
          : relatedVehicles
      );
    } else if (activeTab === TABS.sameEra) {
      setPrevEraRelatedVehicles(
        relatedVehicles.length > 3
          ? relatedVehicles.slice(0, 3)
          : relatedVehicles
      );
    } else if (activeTab === TABS.sameBrand) {
      setPrevBrandRelatedVehicles(
        relatedVehicles.length > 3
          ? relatedVehicles.slice(0, 3)
          : relatedVehicles
      );
    } else if (activeTab === TABS.price) {
      setPrevPriceRelatedVehicles(
        relatedVehicles.length > 3
          ? relatedVehicles.slice(0, 3)
          : relatedVehicles
      );
    }
    return relatedVehicles.slice(0, 3);
  };
  useEffect(() => {
    if (relatedVehiclesData) {
      setRelatedVehicles(getRelatedVehicles());
    }
  }, [relatedVehiclesData]);

  return (
    <>
      <div className={styles.featuredVehicles}>
        <div className={styles.tabsWrapper}>
          <div className={`${styles.heading}`}>RELATED VEHICLES</div>
          <FilterTab
            active={activeTab}
            changeTab={changeActiveTab}
            hasSalePrice={hasSalePrice}
          />
        </div>
        <div className={styles.vehiclesWrapper}>
          {relatedVehicles.length > 0 && !relatedVehiclesLoading
            ? relatedVehicles.map((vehicle, index) => (
                <VehicleCard
                  key={`vehicleCard_${index}`}
                  fluidImages={vehicle.fluidImages}
                  tag={vehicle.tag}
                  makeModel={vehicle.makeModel}
                  auction={vehicle.auction}
                  price={vehicle.price}
                  lotNumber={vehicle.lotNumber}
                  slug={vehicle.slug}
                  showDotsOnHover={true}
                  salesForceId={vehicle?.salesForceId}
                  onlineBiddingAvailable={vehicle.onlineBiddingAvailable}
                />
              ))
            : !relatedVehiclesLoading && <NoResult />}
        </div>
      </div>
    </>
  );
};
export default React.memo(RelatedVehicles);
