import React, { useEffect, useState } from 'react';
import HeroSection from 'components/HeroSection';
import Share from 'components/Share';
import {
  faBookmark,
  faHeart as faHeartReguler,
} from '@fortawesome/free-regular-svg-icons/';
import styles from './vehicleDetails.module.scss';
import Button from 'components/Button';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalCarousel from 'components/Carousel/modalCarousel';
import { useCheckNotAuthenticatedUsers } from 'shared/helpers/Auth';
import { navigate } from 'gatsby';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import { GET_FOLLOWED_VEHICLES } from 'services/graphql/queries/vehicles-catalogue';
import { VIDEO_LOCATIONS } from 'utils/videoConstants';
import FirstToKnow from 'components/FirstToKnowForm';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { SLUGS } from 'utils/webPageTypesConstants';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import BeFirstToKnowIcon from 'svg/BeFirstToKnowIcon';
import { track } from '@amplitude/analytics-browser';
import {
  amplitudeEvents,
  registerToBidEvent,
  trackEvent,
} from 'utils/amplitude';
import { getContentfulItemTitle } from 'shared/helpers/vehicles';

const HeroImage = ({
  toggleQuestionModal,
  salesForceId,
  carouselItems,
  featuredImage,
  pageData,
  followVehicleRequest,
  unfollowVehicleRequest,
  video,
  showVideoFirst,
  videoThumbnail,
  isArchived,
  width,
  isDetails,
  toggleFirstToKnowModal,
  isAutheticated,
  user,
}) => {
  const slugs = useWebPagesSlugs();
  const [modalCarouselIsOpen, setModalCarousel] = useState(false);
  const toggleModalCarousel = () => setModalCarousel(!modalCarouselIsOpen);
  const followVehicle = () => {
    if (isNotAuthenticated) {
      if (isNotAuthenticated === 'unverified') navigate('/auth/unverified');
      else {
        localStorage.setItem(
          'currentRoute',
          `${window.location.pathname}${window.location.search}`
        );
        navigate('/auth/login');
      }
    } else {
      const variables = {
        variables: {
          vehicle: { vehicleId: pageData?.item?.salesForceId ?? null },
        },
      };
      !followedVehicles.includes(pageData?.item?.salesForceId)
        ? followVehicleRequest(variables)
        : unfollowVehicleRequest(variables);
    }
  };

  const isNotAuthenticated = useCheckNotAuthenticatedUsers();

  const { data: followedVehiclesData } = useAuthenticatedQuery(
    GET_FOLLOWED_VEHICLES,
    {
      skip: isNotAuthenticated,
    }
  );

  const followedVehicles =
    followedVehiclesData?.followedVehicles?.vehicles
      ?.map(item => item.salesForceId)
      .filter(item => item) ?? [];

  const [follow, setFollow] = useState(false);

  useEffect(() => {
    if (followedVehicles) {
      setFollow(
        followedVehicles.includes(pageData?.item?.salesForceId) ? true : false
      );
    }
  }, [followedVehicles, pageData]);

  const { videoUrl, videoLocation } = pageData?.item;

  return (
    <>
      <HeroSection
        image={featuredImage}
        onClick={() => toggleModalCarousel()}
        video={
          videoLocation?.length > 0 &&
          videoLocation.includes(VIDEO_LOCATIONS.heroVideo)
            ? videoUrl
            : null
        }
      >
        <div className={styles.bannerText}>
          {/* <Button
            type="app-transparent-blue-button"
            handleClick={e => {
              e.stopPropagation();
              toggleQuestionModal();
            }}
            ariaLabel="Ask a question"
          >
            Ask a Question
          </Button> */}
          <div className={styles.smallBtn}>
            <Share type="app-transparent-white-button" />
            {salesForceId && (
              <Button
                type={`app-transparent-white-button ${
                  follow ? styles.selected : ''
                }`}
                handleClick={e => {
                  e.stopPropagation();
                  _.isFunction(followVehicle) && followVehicle();
                  _.isFunction(followVehicle) &&
                    !follow &&
                    trackEvent(
                      follow
                        ? amplitudeEvents.NOT_FAVORITE
                        : amplitudeEvents.FAVORITES,
                      {
                        email_address: user?.email,
                        lot_name: getContentfulItemTitle(pageData?.item),
                      }
                    );
                }}
                ariaLabel="Follow vehicle"
              >
                <FontAwesomeIcon
                  icon={follow ? faHeartSolid : faHeartReguler}
                />
                <span>Favorite</span>
              </Button>
            )}
            {width < 1023 ? (
              <Button
                handleClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleFirstToKnowModal();
                }}
                type={`${styles.mobileButton}`}
              >
                <BeFirstToKnowIcon stroke={'white'} />
              </Button>
            ) : (
              !isDetails && (
                <Button
                  type={`app-transparent-white-button ${styles.registerButton}`}
                  path={`/${slugs[SLUGS.registerToBid]}`}
                  linkAsButton={true}
                  onClick={() => registerToBidEvent(isAutheticated, user.email)}
                >
                  Register to Bid
                </Button>
              )
            )}
          </div>
        </div>
      </HeroSection>

      {!isArchived && (
        <ModalCarousel
          toggle={toggleModalCarousel}
          isOpen={modalCarouselIsOpen}
          fluidImages={carouselItems}
          closeBtnType={`app-transparent-blue-button`}
          fluidImages={
            carouselItems?.length > 0
              ? showVideoFirst && video
                ? [
                    { url: video, fluid: { src: videoThumbnail } },
                    ...carouselItems,
                  ]
                : !showVideoFirst && video
                ? [
                    ...carouselItems,
                    { url: video, fluid: { src: videoThumbnail } },
                  ]
                : carouselItems
              : []
          }
          video={video}
          showVideoFirst={showVideoFirst}
        />
      )}
    </>
  );
};

export default HeroImage;
