import React from 'react';
import Button from 'components/Button';
import BackgroundImage from 'gatsby-background-image';
import styles from './similarBanner.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { useCheckNotAuthenticatedUsers } from 'shared/helpers/Auth';
import { navigate } from 'gatsby';
import { SLUGS } from 'utils/webPageTypesConstants';

const SimilarBanner = ({ img, toggle, sellCarModal }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const isNotAuthenticated = useCheckNotAuthenticatedUsers();

  return (
    <BackgroundImage className={styles.beach} fluid={{ src: img?.url }}>
      <div className={styles.overlay}></div>
      <div className={`${styles.text} ${styles.textWrapper}`}>
        <div className="d-flex justify-content-between w-100 h-100">
          <h2>
            Have a similar car that you would like to put up for an auction?
          </h2>
          <Button
            type={`app-default-white-button ${styles.whiteButton}`}
            handleClick={() => navigate(`/consignment-inquiry`)}
          >
            Sell Your Car Today
          </Button>
        </div>
      </div>
    </BackgroundImage>
  );
};
export default SimilarBanner;
