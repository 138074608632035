import { gql } from '@apollo/client';

export const useRelatedVehicles = gql`
  query RelatedVehicles {
    relatedVehicles {
      objectID
      make
      hasReservePrice
      modelYear
      lowEstimate
      highEstimate
      uniqueEstimate
      salePrice
      auctionType
      auctionName
      lotNumber
      salesForceId
      currency
      cloudinaryImages {
        public_id
        width
        height
        context {
          custom {
            alt
          }
        }
      }
      model
      auctionEndDate
      upcomingAuctionEndDate
      auctionStartDate
      slug
      privateSalesPrice
      itemType
      title
      venueId
      askingPrice
      askingPriceOverride
      onlineBiddingAvailable
    }
  }
`;
