import React, { useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from 'components/utils/askAQuestion';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import TextInput from 'components/form-elements/TextInput';
import TextArea from 'components/form-elements/TextArea';
import CloseIcon from 'svg/CloseIcon';
import styles from './askQuestionModal.module.scss';
import {
  INQUIRY_EMAIL_TITLE,
  INQUIRY_EMAIL_TYPES,
} from 'utils/inquiryEmailTypes';

const AskQuestionModal = ({
  isOpen,
  toggle,
  lotNumber,
  auctionVenue,
  vehicleName,
  sendEmail,
  url,
  salesforceId,
  emailType = INQUIRY_EMAIL_TYPES.ASK_QUESTION,
}) => {
  const handleSubmit = async values => {
    toggle();
    if (window.dataLayer)
      window.dataLayer.push({
        event: 'contact-us-submit-form',
      });
    const data = {
      pageLink: url,
      vehicleSalesforceId: salesforceId,
      ...values,
      title: INQUIRY_EMAIL_TITLE[emailType],
    };
    delete data.confirmEmail;
    await sendEmail({
      variables: {
        data,
      },
    });
  };
  const formRef = useRef(null);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={styles.askQuestionVehicleDetails}
    >
      <div className={styles.header}>
        <Button handleClick={toggle}>
          <CloseIcon color="silver" />
        </Button>
        <h3>Ask a question</h3>
      </div>
      <ModalBody className={styles.body}>
        <Formik
          initialValues={{
            ...INITIAL_VALUES,
            lotNumber,
            auctionVenue,
            vehicleName,
          }}
          validationSchema={VALIDATION_SCHEMA}
          innerRef={formRef}
          onSubmit={values => handleSubmit(values)}
        >
          {({ isValid, dirty, setFieldValue, isSubmitting }) => {
            return (
              <Form id="contact-us-modal">
                <div className={styles.fieldGroup}>
                  <div className={styles.lotNumberSec}>
                    <div className={styles.fieldSec}>
                      <TextInput
                        name="lotNumber"
                        placeholder="Lot Number"
                        showErrorMessages={false}
                        disabled={true}
                      />
                    </div>
                    <div className={styles.fieldSec}>
                      <TextInput
                        className={styles.field}
                        name="auctionVenue"
                        placeholder="Auction Venue"
                        showErrorMessages={false}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className={styles.field}>
                    <TextInput
                      className={styles.field}
                      name="vehicleName"
                      placeholder="Vehicle Name"
                      showErrorMessages={false}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className={styles.fieldGroup}>
                  <div className={styles.field}>
                    <TextInput
                      name="name"
                      placeholder="Name *"
                      showErrorMessages={false}
                    />
                  </div>

                  <div className={styles.field}>
                    <TextInput
                      name="email"
                      placeholder="Email*"
                      showErrorMessages={false}
                    />
                  </div>
                </div>
                <div className={styles.fieldGroup}>
                  <div className={styles.field}>
                    <TextInput
                      className={styles.field}
                      name="confirmEmail"
                      placeholder="Confirm Email*"
                      showErrorMessages={false}
                    />
                  </div>
                  <div className={styles.field}>
                    <PhoneNumber
                      name="phoneNumber"
                      placeholder="Phone Number*"
                      label="&#42;"
                      showErrorMessages={false}
                    />
                  </div>
                </div>
                <div className={styles.fieldGroup}>
                  <div className={styles.textField}>
                    <TextArea
                      className={styles.field}
                      name="message"
                      placeholder="Message of Inquiry*"
                      showErrorMessages={false}
                    />
                  </div>
                </div>
                <div className={styles.footer}>
                  <Button
                    type="app-primary-button"
                    handleClick={() => {
                      formRef.current.submitForm();
                    }}
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AskQuestionModal;
