import React from 'react';
import { NoResultVehicles } from '../../../../static/svg/NoResultVehicles';
import styles from './vehicleDetails.module.scss';
export const NoResult = () => (
  <>
    <div></div>
    <div className={styles.noResult}>
      <NoResultVehicles />
      <h1>No related vehicles available</h1>
    </div>
  </>
);
