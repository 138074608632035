import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  GET_NEXT_VEHICLES,
  SEND_EMAIL,
} from 'services/graphql/queries/vehicles-catalogue';
import { useLazyQuery } from '@apollo/client';
import { useAuthenticatedMutation } from 'services/graphql/hooks';
import VehicleDetails from 'features/vehicleDetails/vehicle-details';
import { parseSearchParams } from 'shared/helpers/vehicles/vehicleDetails';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';

import { useRelatedVehicles } from 'services/graphql/hooks/relatedVehicles';
import {
  combineCloudinaryWithImages,
  generateCloudinaryUrl,
  generateCloudinaryFluidImage,
} from 'utils/image';
import {
  useFollowedVehiclesUpdate,
  useUnfollowVehiclesUpdate,
} from 'services/graphql/hooks/FollowedVehicles';
import { GET_VEHICLES_CATALOGUE_DATA } from 'services/graphql/queries/vehicles-catalogue';
import { TABS } from 'utils/tabsConstants';
import { changeActiveTab } from 'shared/helpers/vehicles';

const Vehicles = props => {
  const { pageContext, location } = props;
  const { id, next, prev, isArchived } = pageContext;
  const pageData =
    props.data.contentfulPrivateSaleListing || props.data.contentfulLot;
  const auctionSlug = props.data.contentfulLot?.auction?.webpage__auction
    ? props.data.contentfulLot?.auction?.webpage__auction[0]?.slug
    : null;
  const sponsorsData = props.data.allContentfulSponsor.nodes;
  const url = location.href;
  const searchParams = location.search;
  const isBrowser = typeof window !== undefined;
  const [
    followVehicleRequest,
    { loading: followLoading, error: followError },
  ] = useFollowedVehiclesUpdate(pageData?.item?.salesForceId);

  const [
    unfollowVehicleRequest,
    { loading: unfollowLoading, error: unfollowError },
  ] = useUnfollowVehiclesUpdate(pageData?.item?.salesForceId);

  const [searchQuery, setSearchQuery] = useState({});
  const [activeTab, setActiveTab] = useState(TABS.all);
  const [filters, setFilters] = useState({
    filtersInput: {
      make: `${pageData?.item?.make?.name}`,
      auctionType: [],
      itemType: [],
      venue: [],
      auctionYear: [],
      year: {
        start: `${pageData?.item?.modelYear - 10}`,
        end: `${pageData?.item?.modelYear + 10}`,
      },
    },
    sortBy: 'YEAR_ASC',
  });

  const [loadNextAndPrevVehicles, nextAndPrevVehicles] = useLazyQuery(
    GET_NEXT_VEHICLES
  );

  const [loadRelatedVehicles, { data: relatedVehiclesData }] = useLazyQuery(
    useRelatedVehicles
  );
  const [
    loadFilteredRelatedVehicles,
    { loading: filterLoading, data: filteredRelatedVehicles },
  ] = useLazyQuery(GET_VEHICLES_CATALOGUE_DATA, {
    variables: { ...filters, pageNumber: 0 },
  });

  useEffect(() => {
    changeActiveTab(pageData, setFilters, activeTab);
    loadFilteredRelatedVehicles();
  }, [activeTab]);

  useEffect(() => {
    const searchQuery = parseSearchParams(searchParams);
    setSearchQuery(searchQuery);
    if (isBrowser) {
      loadRelatedVehicles();
      if (!!searchQuery) {
        loadNextAndPrevVehicles({
          variables: {
            ...searchQuery,
            vehicleId: id,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [isBrowser, searchParams, id]);

  const [
    sendEmail,
    { error: emailError, data: emailData, loading: isSendingEmail },
  ] = useAuthenticatedMutation(SEND_EMAIL);

  const [carouselImages, setCarouselImages] = useState([]);
  useEffect(() => {
    setCarouselImages(
      combineCloudinaryWithImages(
        pageData,
        1800,
        !!isArchived,
        88,
        pageData.auction ? !pageData.auction.disableImagesWatermarking : true
      )
    );
    // eslint-disable-next-line
  }, []);
  let seoImage;
  let heroSectionImage;
  if (pageData.item.cloudinaryImagesCombined?.length > 0) {
    const {
      public_id,
      height,
      width,
      context,
    } = pageData.item.cloudinaryImagesCombined[0];
    seoImage = {
      url: generateCloudinaryUrl(public_id, 90, width),
      width,
      height,
    };
    heroSectionImage = {
      fluid: generateCloudinaryFluidImage(
        1800,
        public_id,
        width,
        height,
        88,
        false
      ),
      title: pageData.item.title,
      alt: context?.custom?.alt,
    };
  }

  return (
    <>
      {(followLoading || unfollowLoading || filterLoading) && <Spinner />}
      {followError && <Alert color="danger" msg={followError.message} />}
      {unfollowError && <Alert color="danger" msg={unfollowError.message} />}
      <VehicleDetails
        sponsorsData={sponsorsData}
        relatedVehiclesData={relatedVehiclesData}
        filteredRelatedVehicles={filteredRelatedVehicles?.getVehicles?.vehicles}
        nextAndPrevVehicles={nextAndPrevVehicles}
        filtersQuery={searchQuery}
        pageData={pageData}
        sendEmail={sendEmail}
        url={url}
        sendEmailError={emailError}
        salesForceId={pageData?.item?.salesForceId ?? null}
        defaultNextSlug={next}
        defaultPrevSlug={prev}
        emailData={emailData}
        isSendingEmail={isSendingEmail}
        carouselItems={carouselImages}
        followVehicleRequest={followVehicleRequest}
        unfollowVehicleRequest={unfollowVehicleRequest}
        seoImage={seoImage}
        isArchived={isArchived}
        heroSectionImage={heroSectionImage}
        auctionSlug={auctionSlug}
        activeTab={activeTab}
        changeActiveTab={setActiveTab}
        relatedVehiclesLoading={filterLoading}
      />
    </>
  );
};

export default Vehicles;
export const query = graphql`
  query vehicle($id: String, $isArchived: Boolean!) {
    contentfulLot(contentful_id: { eq: $id }) {
      __typename
      lotNumber
      lowEstimate
      highEstimate
      uniqueEstimate
      hasReservePrice
      salePrice
      auctionMobilityId
      askingPrice
      askingPriceOverride
      onlineBiddingAvailable
      title
      slug
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      auction {
        name
        contentful_id
        auctionMobilityId
        currency
        subEvents {
          ... on ContentfulSubEventAuction {
            startDate
          }
        }
        name
        activeAuction
        disableImagesWatermarking
        location {
          address {
            ... on ContentfulAddress {
              id
              addressCountry
            }
            ... on ContentfulAddressFreeform {
              id
              addressCountry
            }
          }
        }
        webpage__auction {
          slug
        }
      }
      onlineBidLink

      item {
        ...vehicleDetails
        ...automobiliaDetails
        ...activeVehicle @skip(if: $isArchived)
        ...activeAutomobilia @skip(if: $isArchived)
      }
      sraNote {
        childMarkdownRemark {
          html
        }
      }
      privateSalesPrice
    }
    contentfulPrivateSaleListing(contentful_id: { eq: $id }) {
      __typename
      salePrice
      askingPrice
      askingPriceOverride
      privateSalesPrice
      currency
      item {
        ...vehicleDetails
        ...automobiliaDetails
        ...activeVehicle @skip(if: $isArchived)
        ...activeAutomobilia @skip(if: $isArchived)
      }
    }
    allContentfulSponsor {
      nodes {
        sponsorName
        logo {
          description
          fluid(maxWidth: 154, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }

  fragment automobiliaDetails on ContentfulAutomobilia {
    title
    salesForceId
    highlights
    note
    salesSpecialist {
      name
      image {
        title
        description
        fixed(width: 154) {
          ...GatsbyContentfulFixed
        }
      }
    }
    cloudinaryImagesCombined {
      public_id
      height
      width
      context {
        custom {
          alt
        }
      }
    }
  }
  fragment activeAutomobilia on ContentfulAutomobilia {
    description {
      childMarkdownRemark {
        html
      }
    }
    videoUrl
    videoLocation
  }

  fragment vehicleDetails on ContentfulVehicle {
    title
    highlights
    specifications
    salesForceId
    make {
      name
    }
    modelYear
    model
    note
    cloudinaryImagesCombined {
      public_id
      height
      width
      context {
        custom {
          alt
        }
      }
    }
    cloudinaryImages1 {
      public_id
      height
      width
      context {
        custom {
          alt
        }
      }
    }
    cloudinaryImages2 {
      public_id
      height
      width
      context {
        custom {
          alt
        }
      }
    }
    cloudinaryImages3 {
      public_id
      height
      width
      context {
        custom {
          alt
        }
      }
    }
    cloudinaryImages4 {
      public_id
      height
      width
      context {
        custom {
          alt
        }
      }
    }
    cloudinaryImages5 {
      public_id
      height
      width
      context {
        custom {
          alt
        }
      }
    }
    cloudinaryImages6 {
      public_id
      height
      width
      context {
        custom {
          alt
        }
      }
    }

    coachwork
    engine
    chassis
    documentation {
      file {
        url
      }
    }

    salesSpecialist {
      name
      image {
        description
        title
        fixed(width: 154) {
          ...GatsbyContentfulFixed
        }
      }
    }
    textYear
  }

  fragment activeVehicle on ContentfulVehicle {
    description {
      childMarkdownRemark {
        html
      }
    }
    exhibitionHighlights
    raceHighlights
    provenance

    videoUrl
    videoLocation
  }
`;
