import qs from 'qs';
import {
  getPrice,
  getAlgoliaItemTitle,
  getAuction,
  getTag,
} from 'shared/helpers/vehicles';
import { combineCloudinaryWithImages } from 'utils/image';

export const parseSearchParams = searchParams => {
  const searchQuery = searchParams ? qs.parse(searchParams.substring(1)) : null;

  if (searchQuery) {
    if (!('filtersInput' in searchQuery)) {
      searchQuery['filtersInput'] = {
        make: [],
        year: undefined,
        estimate: undefined,
        auctionType: [],
        itemType: [],
        venue: [],
        auctionYear: [],
        hasReservePrice: undefined,
        onlineBiddingAvailable: undefined,
      };
    }
    if (typeof searchQuery['pageNumber'] === 'string')
      searchQuery['pageNumber'] = parseInt(searchQuery['pageNumber']);

    if (
      searchQuery.filtersInput.hasReservePrice &&
      typeof searchQuery.filtersInput['hasReservePrice'] === 'string'
    )
      searchQuery.filtersInput['hasReservePrice'] =
        searchQuery.filtersInput['hasReservePrice'] === 'true';

    if (
      searchQuery.filtersInput.onlineBiddingAvailable &&
      typeof searchQuery.filtersInput.onlineBiddingAvailable === 'string'
    )
      searchQuery.filtersInput.onlineBiddingAvailable =
        searchQuery.filtersInput.onlineBiddingAvailable === 'true';
  }
  return searchQuery;
};

export const mapRelatedLots = lots => {
  return lots.map(lot => {
    return {
      tag: getTag(lot),
      makeModel: getAlgoliaItemTitle(lot),
      fluidImages: combineCloudinaryWithImages(lot, 500, 3),
      auction: getAuction(lot),
      lotNumber: lot.lotNumber,
      slug: lot.slug,
      salesForceId: lot.salesForceId,
      price: getPrice(lot, lot?.currency),
      onlineBiddingAvailable: lot.onlineBiddingAvailable,
    };
  });
};
