import * as Yup from 'yup';

const INITIAL_VALUES = {
  lotNumber: '',
  auctionVenue: '',
  vehicleName: '',
  name: '',
  email: '',
  confirmEmail: '',
  phoneNumber: '',
  message: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  lotNumber: Yup.number().transform(value =>
    isNaN(value) ? undefined : value
  ),
  auctionVenue: Yup.string().required('Please enter Auction Venue'),
  vehicleName: Yup.string().required('Please enter Vehicle Name'),
  name: Yup.string().required('Please enter Full Name'),
  message: Yup.string().required('Please enter Message'),
  phoneNumber: Yup.string().required('Phone is required').nullable(),
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.'),
  confirmEmail: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.')
    .test('test', 'Email not match', function (value) {
      const { email } = this.parent;
      return value === email;
    }),
});

export { INITIAL_VALUES, VALIDATION_SCHEMA };
