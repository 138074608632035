import React, { useEffect, useState } from 'react';
import {
  getAuctionYear,
  getContentfulItemTitle,
  getPriceFormatted,
  getEstimates,
  getRelatedVehicles,
} from 'shared/helpers/vehicles';
import { Link, navigate } from 'gatsby';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Button from 'components/Button';
import AskQuestionModal from 'features/vehicleDetails/askQuestionModal';
import Collapse from 'components/Collapse';
import ReadMoreLess from 'components/ReadMoreLess';
import Carousel from 'components/Carousel';
import ArrowWithCircle from 'svg/ArrowWithCircle';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faArrowLeft,
  faAlignRight,
} from '@fortawesome/free-solid-svg-icons';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import MailSubmittedModal from 'components/mailSubmittedModal';
import styles from './vehicleDetails.module.scss';
import { VEHICLE_PATH } from 'utils/pathsConstants';
import { auctionMobilityCalling } from 'shared/helpers/AuctionMobility';
import RedirectAction from 'components/CTA/RedirectAction';
import FeatureToggleManager from 'shared/helpers/FeatureToggle/featureToggleManager';
import { AUCTION_MOBILITY_WIDGET } from 'shared/helpers/FeatureToggle/constants';
import RelatedVehicles from './relatedVehicles';
import HeroImage from './HeroImage';
import { useAuth0 } from '@auth0/auth0-react';
import { VIDEO_LOCATIONS } from 'utils/videoConstants';
import youtubeThumbnail from 'youtube-thumbnail';
import { useCheckNotAuthenticatedUsers } from 'shared/helpers/Auth';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { SLUGS } from 'utils/webPageTypesConstants';

import FirstToKnow from 'components/FirstToKnowForm';
import FirstToKnowModal from '../beFirstToKnowModal/FIrstToKnowModal';

import { TABS } from 'utils/tabsConstants';
import AuctionCard from 'components/UpcomingAuctions/AuctionCard';
import SimilarBanner from '../similarCarBanner';
// import ConsignModal from '../consignModal';
import {
  INQUIRY_EMAIL_TITLE,
  INQUIRY_EMAIL_TYPES,
} from 'utils/inquiryEmailTypes';
import { array } from 'prop-types';
import { useMutation } from '@apollo/client';
import { SEND_EMAIL } from 'services/graphql/queries/vehicles-catalogue';
import { SEND_CONTACT_SPECIALIST_EMAIL } from 'services/graphql/queries/general-emails';
import {
  amplitudeEvents,
  registerToBidEvent,
  trackEvent,
} from 'utils/amplitude';
import { useStaticQueryGetAuctionCatalogue } from 'services/graphql/queries/catalogue';

const VehicleDetails = ({
  pageData,
  relatedVehiclesData,
  filteredRelatedVehicles,
  nextAndPrevVehicles,
  filtersQuery,
  sendEmail,
  url,
  sendEmailError,
  defaultNextSlug,
  defaultPrevSlug,
  salesForceId,
  emailData,
  isSendingEmail,
  carouselItems,
  followVehicleRequest,
  unfollowVehicleRequest,
  seoImage,
  isArchived,
  heroSectionImage,
  auctionSlug,
  activeTab,
  changeActiveTab,
  filteredEraVehicles,
  relatedVehiclesLoading,
}) => {
  const { catalogueAuctions } = useStaticQueryGetAuctionCatalogue();
  const { isAuthenticated, isLoading, user } = useAuth0();
  const slugs = useWebPagesSlugs();
  const nextVehicleSlug = nextAndPrevVehicles.data?.NextAndPrevVehicles
    ? nextAndPrevVehicles.data.NextAndPrevVehicles.nextVehicleSlug
    : defaultNextSlug;

  const prevVehicleSlug = nextAndPrevVehicles.data?.NextAndPrevVehicles
    ? nextAndPrevVehicles.data.NextAndPrevVehicles.prevVehicleSlug
    : defaultPrevSlug;

  const nextVehiclePage = nextAndPrevVehicles.data?.NextAndPrevVehicles
    ? nextAndPrevVehicles.data.NextAndPrevVehicles.nextVehiclePage
    : (filtersQuery && filtersQuery.pageNumber) || 0;

  const prevVehiclePage = nextAndPrevVehicles.data?.NextAndPrevVehicles
    ? nextAndPrevVehicles.data.NextAndPrevVehicles.prevVehiclePage
    : (filtersQuery && filtersQuery.pageNumber) || 0;

  const title = getContentfulItemTitle(pageData.item);

  // Auction Mobility widget
  const [amAuctionWidget, setAmAuctionWidget] = useState(null);
  const [amAuctionWidgetError, setAmAuctionWidgetError] = useState(false);

  const featureToggleManager = new FeatureToggleManager();

  const [width, setWidth] = useState(0);
  const breakPoint = 1023;
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window?.innerWidth));
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (pageData) {
      if (
        featureToggleManager.isEnabled(AUCTION_MOBILITY_WIDGET) &&
        pageData?.auction?.activeAuction
      ) {
        setAmAuctionWidget(
          auctionMobilityCalling(
            pageData?.auction?.auctionMobilityId,
            [pageData?.auctionMobilityId],
            amAuctionWidget,
            'detail'
          )
        );
      }
    }
    // eslint-disable-next-line
  }, [pageData]);

  useEffect(() => {
    if (amAuctionWidget) {
      amAuctionWidget.setWidgetHandler('system', status => {
        setAmAuctionWidgetError(
          status?.websocket_state !== 'connected' ? true : false
        );
      });
    }
  }, [amAuctionWidget]);

  const placeBidButton = {
    buttonText: 'Place a Bid',
    action: {
      url: pageData.onlineBidLink,
      openInANewTab: true,
    },
    id: pageData.title,
    style: 'Button: Blue Text / No Background',
  };

  const isNotAuthenticated = useCheckNotAuthenticatedUsers();
  const [
    sendEmailForm,
    { error: emailErrorForm, data: emailDataForm, loading: isSendingEmailForm },
  ] = useMutation(SEND_EMAIL);

  const [questionModal, setQuestionModal] = useState(false);
  const [firstToKnowModal, setFirstToKnowModal] = useState(false);
  const [firstToKnowForm, setFirstToKnowForm] = useState(true);
  //toggling Modal
  const toggleFirstToKnowModal = () => {
    setFirstToKnowModal(!firstToKnowModal);
    setFirstToKnowForm(true);
  };
  const toggleFirstToKnowForm = () => {
    setFirstToKnowForm(false);
  };
  //toggling Modal
  const toggleQuestionModal = () => {
    if (!questionModal && isNotAuthenticated) {
      if (isNotAuthenticated === 'unverified') navigate('/auth/unverified');
      else {
        localStorage.setItem(
          'currentRoute',
          `${window.location.pathname}${window.location.search}`
        );
        navigate('/auth/login');
      }
    } else setQuestionModal(!questionModal);
  };

  const { videoLocation, videoUrl } = pageData.item;

  const videoUrlIfCarousel =
    videoLocation?.length > 0 &&
    videoLocation.includes(VIDEO_LOCATIONS.carousel)
      ? videoUrl
      : null;

  const videoLocationInsideCarousel =
    videoLocation?.length === 1 &&
    videoLocation.includes(VIDEO_LOCATIONS.carousel);

  const videoThumbnail = videoUrl && youtubeThumbnail(videoUrl)?.medium?.url;

  const hasEstimate =
    pageData.lowEstimate || pageData.highEstimate || pageData.uniqueEstimate;
  const isDetails = pageData?.salePrice !== null ? true : false;

  const [toggleSellCar, setToggleSellCar] = useState(false);
  const toggleBannerModal = () => {
    setToggleSellCar(!toggleSellCar);
  };
  const isCatalogue =
    hasEstimate ||
    pageData?.items?.highlights ||
    pageData?.items?.specifications;
  const submit = async values => {
    if (window.dataLayer)
      window.dataLayer.push({
        event: 'contact-us-submit-form',
      });
    const data = {
      pageLink: url,
      vehicleSalesforceId: salesForceId,
      vehicleName: pageData?.item?.title,
      lotNumber: pageData?.lotNumber ? pageData.lotNumber : 0,
      auctionVenue: pageData?.auction
        ? pageData?.auction?.name
        : 'Private Sale',
      ...values,
      isSold: isDetails,
      title:
        INQUIRY_EMAIL_TITLE[
          isDetails
            ? INQUIRY_EMAIL_TYPES.SOLD_VEHICLE
            : isCatalogue
            ? INQUIRY_EMAIL_TYPES.LEARN_MORE_ABOUT_VEHICLE
            : INQUIRY_EMAIL_TYPES.BE_THE_FIRST_TO_KNOW
        ] + pageData?.item?.title,
    };
    delete data.confirmEmail;
    await sendEmailForm({
      variables: {
        data,
      },
    }).then(res => {
      const eventProperties = {
        email_address: user?.email,
        lot: pageData?.lotNumber,
      };
      if (isDetails) {
        trackEvent(amplitudeEvents.SOLD_FORM_SUBMISSION, eventProperties);
      } else if (!isCatalogue) {
        trackEvent(amplitudeEvents.BE_FIRST_TO_KNOW, eventProperties);
      }
      toggleFirstToKnowForm();
    });
  };
  const currency = pageData?.currency || pageData?.auction?.currency;
  const isBrowser = () => typeof window !== 'undefined';
  return (
    <>
      {(nextAndPrevVehicles.loading || isSendingEmailForm, isSendingEmail) && (
        <Spinner />
      )}
      <Layout transparentHeader={true} paddingTop={false}>
        {(sendEmailError || emailErrorForm) && (
          <Alert
            color="danger"
            msg={
              sendEmailError ? sendEmailError.message : emailErrorForm.message
            }
          />
        )}
        {/* {emailData?.SendVehicleInquiryEmail?.code === 202 && (
          <MailSubmittedModal />
        )} */}
        <div className={styles.vehicleDetails}>
          <SEO
            title={title ?? 'Vehicle Details'}
            metaTitle={
              pageData?.title ??
              `${title} | ${pageData?.auction?.name} | Gooding & Company`
            }
            description={
              pageData?.description?.childMarkdownRemark?.rawMarkdownBody ??
              `View the ${title} at Gooding & Company's ${pageData?.auction?.name}. Contact us for more information.`
            }
            image={seoImage}
            link={`${isBrowser() && window.location.origin}/lot/${
              pageData.slug
            }`}
          />
          <HeroImage
            salesForceId={salesForceId}
            toggleQuestionModal={toggleQuestionModal}
            toggleFirstToKnowModal={toggleFirstToKnowModal}
            carouselItems={carouselItems}
            featuredImage={heroSectionImage}
            pageData={pageData}
            followVehicleRequest={followVehicleRequest}
            unfollowVehicleRequest={unfollowVehicleRequest}
            video={videoUrlIfCarousel}
            showVideoFirst={videoLocationInsideCarousel}
            videoThumbnail={videoThumbnail}
            isArchived={isArchived}
            width={width}
            isAuthenticated={isAuthenticated}
            user={user}
            isDetails={isDetails}
          />
          <div className={styles.documentation}>
            <div className="col-sm-12">
              <div className={styles.lot}>
                {!!prevVehicleSlug && !nextAndPrevVehicles.loading && (
                  <Link
                    title="previous lot"
                    to={`${VEHICLE_PATH(prevVehicleSlug)}/?${
                      filtersQuery
                        ? qs.stringify({
                            ...filtersQuery,
                            pageNumber: prevVehiclePage,
                          })
                        : ''
                    }`}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Link>
                )}
                {pageData.lotNumber &&
                pageData.lotNumber !== '0' &&
                pageData.lotNumber !== 0 ? (
                  <h2>Lot {pageData.lotNumber}</h2>
                ) : (
                  ''
                )}
                {!!nextVehicleSlug && !nextAndPrevVehicles.loading && (
                  <Link
                    title="next lot"
                    to={`${VEHICLE_PATH(nextVehicleSlug)}/?${
                      filtersQuery
                        ? qs.stringify({
                            ...filtersQuery,
                            pageNumber: nextVehiclePage,
                          })
                        : ''
                    }`}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                )}
              </div>
            </div>
            {/* <div> */}
            <div className={styles.specificationDetail}>
              <div className="">
                <div className={styles.modal}>
                  <h2>
                    {pageData.auction
                      ? `${getAuctionYear(
                          pageData.auction.subEvents
                        )} \xa0 | \xa0 `
                      : ''}
                  </h2>
                  <a
                    className={styles.textWarmgray}
                    onClick={
                      auctionSlug
                        ? () => navigate(`/auction/${auctionSlug}`)
                        : () => {}
                    }
                  >
                    {pageData.auction ? pageData.auction.name : 'Private Sale'}
                  </a>
                  {pageData.onlineBiddingAvailable ? <ArrowWithCircle /> : ''}
                </div>
                <h1 className={styles.mainHeading}>{title}</h1>
                <div className={styles.owner}>
                  {pageData.item.note && <p>{pageData.item.note}</p>}
                  {pageData.item.coachwork && (
                    <p>Coachwork by {pageData.item.coachwork}</p>
                  )}
                </div>
                {width < breakPoint && (
                  <Button
                    type={`app-transparent-blue-button ${styles.registerBid}`}
                    path={`/${slugs[SLUGS.registerToBid]}`}
                    linkAsButton={true}
                    handleClick={() =>
                      registerToBidEvent(isAuthenticated, user.email)
                    }
                  >
                    Register to Bid
                  </Button>
                )}
                <div className={styles.divider}></div>
                {pageData.salePrice !== undefined &&
                  pageData.salePrice !== null && (
                    <div className={styles.specification}>
                      <h2>
                        {`SOLD ${
                          !pageData.privateSalesPrice
                            ? ` ${getPriceFormatted(
                                pageData.salePrice,
                                currency
                              )}`
                            : ''
                        }`}
                      </h2>
                    </div>
                  )}
                <div className={styles.specification}>
                  {(pageData.askingPrice || pageData.askingPriceOverride) &&
                  (!pageData.salePrice ||
                    pageData.__typename === 'ContentfulPrivateSaleListing') ? (
                    <>
                      <p>Asking Price</p>
                      <h2 className={styles.amount}>
                        <span className={styles.amount}>
                          {pageData.askingPriceOverride
                            ? 'Price Available Upon Request'
                            : getPriceFormatted(pageData.askingPrice, currency)}
                        </span>
                      </h2>
                    </>
                  ) : hasEstimate ? (
                    <>
                      <p>Estimate</p>
                      <h2 className={styles.amount}>
                        <span>
                          {pageData.uniqueEstimate
                            ? pageData.uniqueEstimate
                            : getEstimates(
                                pageData.lowEstimate,
                                pageData.highEstimate,
                                currency
                              )}
                        </span>
                        <span className={styles.regular}>
                          {pageData.hasReservePrice ? '' : `| Without Reserve`}
                        </span>
                      </h2>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className={styles.specificationChassis}>
                  {pageData.item.chassis && (
                    <div className={styles.specification}>
                      <p>Chassis</p>
                      <h2 className={styles.amount}>{pageData.item.chassis}</h2>
                    </div>
                  )}
                  {pageData.item.engine && (
                    <div className={styles.specification}>
                      <p>Engine</p>
                      <h2 className={styles.amount}>{pageData.item.engine}</h2>
                    </div>
                  )}
                </div>
                <div className={styles.divider}></div>
                <div className={styles.details}>
                  {pageData?.item?.highlights && (
                    <div>
                      <h3>Car Highlights</h3>
                      {pageData.item.highlights.map((item, i) => (
                        <p
                          key={`highlights-${i}`}
                          dangerouslySetInnerHTML={{ __html: item }}
                        />
                      ))}
                    </div>
                  )}
                  {pageData?.item?.specifications && (
                    <div>
                      <h3>Technical Specs</h3>
                      {pageData.item.specifications.map((item, i) => (
                        <p
                          key={`specifications-${i}`}
                          dangerouslySetInnerHTML={{ __html: item }}
                        />
                      ))}
                    </div>
                  )}
                  {pageData.sraNote && (
                    <div className="test">
                      <h3 className={styles.sraNote}>
                        <FontAwesomeIcon icon={faAlignRight} />
                        Saleroom Addendum
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: pageData.sraNote.childMarkdownRemark.html,
                        }}
                      ></div>
                    </div>
                  )}

                  {featureToggleManager.isEnabled(AUCTION_MOBILITY_WIDGET) &&
                    pageData?.auctionMobilityId &&
                    pageData?.auction?.auctionMobilityId && (
                      <div
                        id={`am-target-${pageData?.auctionMobilityId}`}
                        lot-id={pageData?.auctionMobilityId}
                        className="d-flex amCustomStyles amCustomStylesDetails mb-4 flex-column align-items-center"
                      >
                        <div>
                          <am-lot-status-label></am-lot-status-label>
                          <am-bid-status-label></am-bid-status-label>
                        </div>
                        <am-reserve-label></am-reserve-label>
                        <am-timer></am-timer>
                        {isLoading ? null : isAuthenticated ? (
                          <am-bid-box></am-bid-box>
                        ) : (
                          pageData?.auction?.activeAuction && (
                            <Button
                              type="app-primary-button AM-login-button"
                              path={'/login'}
                              linkAsButton={true}
                              handleClick={registerToBidEvent(
                                isAuthenticated,
                                user.email
                              )}
                            >
                              Register to Bid
                            </Button>
                          )
                        )}
                      </div>
                    )}

                  {pageData.onlineBidLink &&
                  pageData.auction?.activeAuction === true ? (
                    <div className={styles.onlineBidLink}>
                      <RedirectAction
                        buttonStyle="app-primary-button no-border"
                        callToAction={placeBidButton}
                        extraStyle="linkAsButton"
                        isTextWithOrPrefix={false}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                {pageData.item.documentation && (
                  <Button
                    type="app-transparent-blue-button"
                    handleClick={() => {
                      window.open(pageData.item.documentation.file.url);
                    }}
                  >
                    Documentation
                  </Button>
                )}
              </div>
              <div>
                {width > breakPoint ? (
                  <FirstToKnow
                    url={url}
                    sendEmail={sendEmail}
                    isDetails={isDetails}
                    isPrivateSale={pageData?.auction ? false : true}
                    salesforceId={salesForceId}
                    toggle={toggleFirstToKnowForm}
                    submit={submit}
                    isForm={firstToKnowForm}
                    lotNumber={pageData?.lotNumber}
                    vehicle={pageData?.item?.title}
                    auction={pageData?.auction?.name}
                    isCatalogue={
                      hasEstimate ||
                      pageData?.items?.highlights ||
                      pageData?.items?.specifications
                    }
                  />
                ) : (
                  <FirstToKnowModal
                    url={url}
                    sendEmail={sendEmailForm}
                    isDetails={isDetails}
                    isOpen={firstToKnowModal}
                    toggle={toggleFirstToKnowModal}
                    salesforceId={salesForceId}
                    lotNumber={pageData?.lotNumber}
                    vehicle={pageData?.item?.title}
                    auction={pageData?.auction?.name}
                    toggleIsForm={toggleFirstToKnowForm}
                    isForm={firstToKnowForm}
                    submit={submit}
                    isCatalogue={
                      hasEstimate ||
                      pageData?.items?.highlights ||
                      pageData?.items?.specifications
                    }
                  />
                )}
              </div>
            </div>
            {isDetails && (
              <div className={styles.similarBanner}>
                <SimilarBanner toggle={toggleBannerModal} img={seoImage} />
              </div>
            )}
          </div>

          {/* <ConsignModal
            sellCar={toggleSellCar}
            toggle={toggleBannerModal}
            slug={`/lot/${pageData.item.title}/`}
          /> */}
          {!isArchived && (
            <div className={styles.carouselContainer}>
              <Carousel
                size="lg"
                fluidImages={carouselItems}
                title={pageData.item.title}
                video={videoUrlIfCarousel}
                showVideoFirst={videoLocationInsideCarousel}
                videoThumbnail={videoThumbnail}
              />
            </div>
          )}
          <div className={styles.highlights}>
            <div>
              <div className={styles.collapseSection}>
                {pageData.item.provenance && (
                  <Collapse
                    title="Provenance"
                    description={pageData.item.provenance}
                  />
                )}
                {pageData.item.raceHighlights && (
                  <Collapse
                    title="Race Highlights"
                    description={pageData.item.raceHighlights ?? ''}
                  />
                )}
                {pageData.item.exhibitionHighlights && (
                  <Collapse
                    title="Exhibition Highlights"
                    description={pageData.item.exhibitionHighlights ?? ''}
                  />
                )}
              </div>
              {pageData.item.description && (
                <ReadMoreLess
                  content={pageData.item.description.childMarkdownRemark.html}
                  isMarkdown={true}
                />
              )}
            </div>
          </div>

          <RelatedVehicles
            relatedVehiclesData={getRelatedVehicles(
              filteredRelatedVehicles,
              activeTab,
              pageData
            )}
            hasSalePrice={pageData.salePrice}
            activeTab={activeTab}
            changeActiveTab={changeActiveTab}
            relatedVehiclesLoading={relatedVehiclesLoading}
          />
        </div>
        <AskQuestionModal
          isOpen={questionModal}
          lotNumber={pageData.lotNumber ?? '-'}
          vehicleName={title}
          auctionVenue={
            pageData.auction ? pageData.auction.name : 'Private Sale'
          }
          toggle={toggleQuestionModal}
          sendEmail={sendEmail}
          url={url}
          salesforceId={pageData.item.salesForceId}
        />
        {amAuctionWidgetError && (
          <Alert
            color="danger"
            msg={`A connection issue has occurred, please refresh the page. `}
          />
        )}
      </Layout>
    </>
  );
};

export default VehicleDetails;
