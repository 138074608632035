import React from 'react';
import Button from 'components/Button';
import BackgroundImage from 'gatsby-background-image';
import './upcomingAuctions.scss';

const AuctionCard = ({
  img,
  location,
  date,
  time,
  buttons,
  path,
  info,
  subInfo,
  mailAddress,
  handleClick,
  fullWidth,
}) => {
  return (
    <BackgroundImage className="beach" fluid={img}>
      <div className="overlay"></div>
      <div className={`text ${fullWidth ? 'textWrapper' : ''}`}>
        <div className="d-flex justify-content-between flex-column h-100">
          <div>
            <h2>{location}</h2>
            {date && <p>{date}</p>}
            {time && <p>{time}</p>}
            {info && <p className="info">{info}</p>}
            {subInfo && <p className="subInfo">{subInfo}</p>}
          </div>
          <div>{mailAddress && <p>{mailAddress}</p>}</div>
        </div>
        <div>
          {buttons &&
            buttons.map((button, index) => (
              <Button
                key={index}
                type="app-default-white-button"
                handleClick={() => handleClick(path[index])}
              >
                {button}
              </Button>
            ))}
        </div>
      </div>
    </BackgroundImage>
  );
};
export default AuctionCard;
