import _ from 'lodash';

// Intialize auction mobility widget
export const auctionMobilityCalling = (
  auctionMobilityId,
  lotsIds,
  amAuctionWidget,
  layout = 'basic'
) => {
  const isBrowser = typeof window !== undefined;
  if (isBrowser && window.amw && lotsIds?.length && auctionMobilityId) {
    if (amAuctionWidget?.auctionId === auctionMobilityId) {
      if (lotsIds?.length > 50) {
        const chunksOfLotsIds = _.chunk(lotsIds, 50);
        chunksOfLotsIds.forEach(lots => {
          amAuctionWidget.loadLots(lots);
        });
      } else {
        amAuctionWidget.loadLots(lotsIds);
      }
      return amAuctionWidget;
    } else {
      const amAuctionWidgetNewConst = new window.amw.default.AmWidget({
        auctionId: auctionMobilityId,
        lots: lotsIds,
        layout: layout,
        url: process.env.GATSBY_AM_BID_URL + '/',
        wrapperPrefix: 'am-target',
        domain: process.env.GATSBY_AUCTION_MOBILITY_DOMAIN,
        redirectUri: process.env.GATSBY_AUCTION_MOBILITY_REDIRCT_URI,
        clientID: process.env.GATSBY_AM_AUTH0_CLIENT_ID,
      });
      amAuctionWidgetNewConst.init();
      return amAuctionWidgetNewConst;
    }
  }
};
